var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pt-10" },
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { xl: "9" } },
            [
              _vm.fetchingUserInfo === false && _vm.userInfo
                ? _c("v-container", { attrs: { "fill-height": "" } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-center justify-center loadingClass"
                      },
                      [
                        _vm.fetchingOrgs || _vm.currentOrgData
                          ? _c("v-progress-circular", {
                              attrs: {
                                indeterminate: "",
                                color: "secondary",
                                size: "64",
                                width: "6"
                              }
                            })
                          : _vm._e(),
                        _c("div", { staticClass: "mt-3" }, [
                          _vm.fetchingOrgs
                            ? _c("span", [
                                _vm._v("Loading your organizations...")
                              ])
                            : _c("span", [
                                _vm.currentOrgData
                                  ? _c("span", [
                                      _vm._v("Opening dashboard... ")
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        "Ooops! It seems you have not accepted your invitation yet. Please check your emails and follow the link to accept. If you encounter issues, you can "
                                      ),
                                      _c(
                                        "a",
                                        { on: { click: _vm.showIntercomChat } },
                                        [_vm._v("ask for help")]
                                      )
                                    ])
                              ])
                        ])
                      ],
                      1
                    )
                  ])
                : _vm.fetchingUserInfo === false &&
                  !_vm.userInfo &&
                  (!_vm.loginMethod ||
                    (_vm.loginMethod && _vm.loginMethod[0] !== "password"))
                ? _c(
                    "v-alert",
                    {
                      attrs: {
                        outlined: "",
                        type: "warning",
                        prominent: "",
                        border: "left"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex flex-column",
                          staticStyle: { "max-width": "1000px" }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "headline font-weight-bold mb-3" },
                            [_vm._v("No invitation / Wrong login method")]
                          ),
                          _c("span", { staticClass: "py-3 title" }, [
                            _vm._v(
                              "An error has occurred during login. This might be caused by any of the following issues:"
                            )
                          ]),
                          _c(
                            "ul",
                            { staticClass: "subtitle-2 font-weight-medium" },
                            [
                              _c("li", [
                                _vm._v(
                                  " You don't have a valid invitation from a faculty member to use Nuvolos. Please arrange to receive an invitation e-mail and follow the necessary steps to create an account. "
                                )
                              ]),
                              _c("li", [
                                _vm._v(
                                  " You have logged in with the wrong method. Make sure you login with the same method you used to signup to Nuvolos. "
                                )
                              ])
                            ]
                          ),
                          _c("v-divider", {
                            staticClass: "my-4 error",
                            staticStyle: { opacity: "0.22" }
                          }),
                          _c(
                            "v-row",
                            { attrs: { align: "center", "no-gutters": "" } },
                            [
                              _c("v-col", { staticClass: "grow" }, [
                                _vm._v("Thank you for your understanding!")
                              ]),
                              _c("div", { staticClass: "flex-grow-1" }),
                              _c(
                                "v-col",
                                { staticClass: "shrink" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "error", outlined: "" },
                                      on: { click: _vm.logout }
                                    },
                                    [_vm._v("logout")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _vm.fetchingUserInfo === false &&
                  !_vm.userInfo &&
                  _vm.loginMethod &&
                  _vm.loginMethod[0] === "password"
                ? _c(
                    "v-alert",
                    {
                      attrs: {
                        outlined: "",
                        type: "warning",
                        prominent: "",
                        border: "left"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex flex-column" },
                        [
                          _c(
                            "span",
                            { staticClass: "headline font-weight-bold mb-3" },
                            [_vm._v("Wrong login method")]
                          ),
                          _c("span", { staticClass: "py-3 subtiitle-1" }, [
                            _vm._v(
                              " We have detected that you have signed up with your email and password. Please log out and use your email "
                            ),
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v(_vm._s(_vm.$auth.profile.email))
                            ]),
                            _vm._v(" and your password to sign in. ")
                          ]),
                          _c("v-divider", {
                            staticClass: "my-4 error",
                            staticStyle: { opacity: "0.22" }
                          }),
                          _c(
                            "v-row",
                            { attrs: { align: "center", "no-gutters": "" } },
                            [
                              _c("v-col", { staticClass: "grow" }, [
                                _vm._v("Thank you for your understanding!")
                              ]),
                              _c("div", { staticClass: "flex-grow-1" }),
                              _c(
                                "v-col",
                                { staticClass: "shrink" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "error", outlined: "" },
                                      on: { click: _vm.logout }
                                    },
                                    [_vm._v("logout")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }